import { GET, POST } from "./web.request";

export const submitData = (payload) => {
    return POST(`https://guava-navybean-8wnud23u3gfo1bix.salad.cloud/submit`, payload);
};

//list_files
export const listFiles = (payload) => {
    return GET(`https://guava-navybean-8wnud23u3gfo1bix.salad.cloud/list_files/`, payload);
};

//file upload 
export const uploadFile = (payload) => {
    return POST(`https://guava-navybean-8wnud23u3gfo1bix.salad.cloud/upload_documents/`, payload);
};
