import axios from "axios";

export const POST = async (url, data) => {
    try {
        const response = await axios.post(url, data);
        if (response.data.status) {
            return {
                success: true,
                data: response.data.data,
                message: response.data.message,
            };
        } else {
            return {
                success: false,
                data: response.data.data,
                message: response.data.message,
            };
        }
    } catch (error) {
        return {
            success: false,
            data: [],
            message: error.message || "something_went_wrong",
        };
    }
}

export const GET = async (url, data) => {
    try {
        const response = await axios.get(url, data);
        if (response.data.status) {
            return {
                success: true,
                data: response.data.data,
                message: response.data.message,
            };
        } else {
            return {
                success: false,
                data: response.data.data,
                message: response.data.message,
            };
        }
    } catch (error) {
        return {
            success: false,
            data: [],
            message: error.message || "something_went_wrong",
        };
    }
}
