import React, { useState, useEffect, useRef } from "react";
import ThankYouModal from "./Popup";
import { submitData, listFiles, uploadFile } from "./Api.service";
import Loading from "./Loader";
import LogImage from "./logo.jpeg";
import LogImage1 from "./WhatsApp Image 2024-07-11 at 6.05.57 PM.jpeg";

import { ChevronDown, ChevronUp, Search, SearchCheck } from "lucide-react";
import BackdropComponent from "./Loader";
import { Discuss, MagnifyingGlass } from "react-loader-spinner";
const Home = () => {
  const [submitted, setSubmitted] = useState(false);
  const [question, setQuestion] = useState("");
  const [apiQuestion, setApiQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [feedbacks, setfeedbacks] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selecteDropDownData, setSelectedDropDownData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownData, setDropdownData] = useState([]);
  const fileInput = useRef(null);
  const [openBackdrop, setOpenBreakdrop] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // if (file && (file.type === "application/pdf" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "image/jpeg")) {
    if (file && file.size < 5000000) {
      setSelectedFile(file);
      setSelectedDropDownData("");
    } else {
      setSelectedFile(null);
      fileInput.current.value = "";
      alert("Please select file below 5 MB.");
    }
    // } else {
    //   setSelectedFile(null);
    //   fileInput.current.value = "";
    //   alert("Please select a valid file.");
    // }
  };

  const handelFileAdd = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const { data, message, success } = await uploadFile(formData);
      if (success) {
        getDropdownData();
        setSelectedFile(null);
        setOpen(true);
        fileInput.current.value = "";
      } else {
        console.log("FILED");
        alert("Failed to upload file.");
      }
    }
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  }, [open]);

  const getDropdownData = async () => {
    const { data, message, success } = await listFiles();
    if (success) {
      setDropdownData(data);
    } else {
      setDropdownData([]);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setLoading(true);
    if (selecteDropDownData) {
      if (question !== "") {
        const formData = new FormData();
        formData.append("question", question);
        if (selecteDropDownData) {
          formData.append("filename", selecteDropDownData);
        } else {
          formData.append("file", selectedFile);
        }

        const { data, message, success } = await submitData(formData);
        if (success) {
          setAnswer(data?.answer);
          setApiQuestion(data?.question);
          // setQuestion("");
          setSubmitted(true);
          setIsLoading(false);
          // getDropdownData();
          setLoading(false);
        } else {
          console.log("FILED");
          setIsLoading(false);
          setLoading(false);
        }
      } else {
        alert("Please enter your question.");
        setIsLoading(false);
        setLoading(false);
      }
    } else {
      alert("Please select a file first.");
      setIsLoading(false);
      setLoading(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    if (feedbacks !== "") {
      setIsModalOpen(true);
    } else {
      alert("Please enter feedback Yes/No");
    }
  };

  const handleCloseModal = () => {
    setSubmitted(false);
    setfeedbacks("");
    setQuestion("");
    setIsModalOpen(false);
  };

  const resetAll = () => {
    console.log("Button Cliekced");
    setSelectedFile("");
    setSelectedDropDownData("");
    setQuestion("");
    setAnswer();
  };
  useEffect(() => {
    getDropdownData();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <MagnifyingGlass
            visible={true}
            height="120"
            width="120"
            ariaLabel="magnifying-glass-loading"
            wrapperStyle={{}}
            wrapperClass="magnifying-glass-wrapper"
            glassColor="#c0efff"
            color="#1E2D24"
          />
        </div>
      ) : (
        <div className="sm:mx-auto mt-7 container bg-[#D4EBF3] mx-5">
          {/* <Loading isLoading={isLoading} /> */}
          <div className="flex justify-center items-center flex-col">
            <div className="flex justify-center my-3 gap-12">
            <img
                src={LogImage1}
                width="17%"
                style={{ boxShadow: "3px -1px 15px -3px #5fb5d3" }}
                className="w-[250px]"
              />
              <img
                src={LogImage}
                width="17%"
                style={{ boxShadow: "3px -1px 15px -3px #5fb5d3" }}
                className="w-[250px] h-[90px]"
              />
              
            </div>
            <div className="flex flex-col justify-center mt-3">
              <div className="grid sm:grid-cols-2 gap-20  grid-cols-1 w-full">
                <div className="flex gap-2   rounded-lg  ">
                  <div className="flex flex-col gap-2 w-full">
                    <span className="text-xl font-semibold">
                      Upload the Document
                    </span>
                    <span className="font-medium text-xs">
                      (Supports PDF, DOCX, JPG formats And size should be less
                      than 5 MB )
                    </span>
                    <div className="flex  gap-2 w-full">
                      <div className="flex flex-col items-center justify-center border border-dashed border-gray-400 rounded-lg p-6 bg-slate-200">
                        <input
                          type="file"
                          className="w-full"
                          accept=".pdf,.docx,.jpg"
                          onChange={handleFileChange}
                          required
                          placeholder="Upload the Document"
                          ref={fileInput}
                        />
                      </div>
                      <div className="flex justify-center items-center w-[150px] h-fit">
                        {!open ? (
                          <button
                            className="bg-black border-1 border-black font-medium text-white px-4 py-2 mt-5 w-fit rounded-md shadow-sm hover:shadow-xl h-fit cursor-pointer"
                            onClick={handelFileAdd}
                            disabled={!selectedFile}
                          >
                            Upload
                          </button>
                        ) : (
                          <div
                            style={{
                              //make some animation
                              animation: "fadeIn 0.5s",
                              backgroundColor: "green",
                              color: "white",
                              padding: "10px",
                              borderRadius: "2px",
                              marginTop: "10px",
                              textAlign: "center",
                              width: "fit-content",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            File uploaded successfully
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div className=" my-3 relative">
                      <select
                        className="w-full p-2 border border-gray-400 rounded-lg"
                        onChange={(e) => {
                          if (e.target.value) {
                            setSelectedDropDownData(e.target.value);
                          } else {
                            setSelectedFile(null);
                          }
                          if (fileInput.current) {
                            fileInput.current.value = "";
                          }
                        }}
                        value={selecteDropDownData}
                      >
                        <option value="">Select File</option>
                        {dropdownData &&
                          dropdownData?.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.value}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex gap-2 mt-2 rounded-lg ">
                    <div className="flex flex-col gap-2 w-full">
                      <span className="text-xl font-semibold capitalize">
                        Ask a question to the document
                      </span>
                      <div className="flex items-center border border-gray-400 rounded-full shadow-sm p-1.5">
                        <Search
                          color="#9ca3af"
                          size={20}
                          style={{ marginLeft: "10px" }}
                        />
                        <input
                          type="text"
                          className=" px-4 py-2 rounded-lg border-0 focus:outline-none text-lg w-full"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                          required
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSubmit();
                            }
                          }}
                        />
                      </div>
                      <div className="flex gap-3 justify-end">
                        <button
                          onClick={handleSubmit}
                          className="bg-black text-white px-4 py-2 mt-5 w-fit rounded-md shadow-sm hover:shadow-xl h-fit"
                        >
                          Search
                        </button>

                        <button
                          onClick={() => resetAll()}
                          className="bg-white shadow-sm hover:shadow-xl text-black px-4 py-2 mt-5 w-fit rounded-md h-fit"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Conditionally render second design after submit */}
          {submitted && (
            <div className="py-8">
              {/* <div className="flex gap-2 items-center flex-wrap pl-5"> */}
                <div className="text-2xl font-bold">
                  Question: {apiQuestion}
                </div>
              {/* </div> */}

              <div className="text-2xl font-medium mt-3">Answer: {answer}</div>
              {/* <div className="flex gap-4 mt-3 items-center">
            <span className="text-lg font-semibold">
              Was the answer correct? (yes/no):
            </span>
            <input
              type="text"
              className="w-[200px] h-fit border-2 border-black "
              value={feedbacks}
              onChange={(e) => setfeedbacks(e.target.value)}
              required
            />
            <button
              //   onClick={handleAnswerSubmit}
              onClick={handleOpenModal}
              className="bg-transparent shadow-md font-bold border-black text-black hover:shadow-xl px-4 py-2 w-fit rounded-md"
            >
              Submit
            </button>
          </div> */}
            </div>
          )}
          <ThankYouModal isOpen={isModalOpen} onClose={handleCloseModal} />
        </div>
      )}
    </>
  );
};

export default Home;
